<template>
  <div v-if="loadingAutoUpgrade">
    <ProgressSpinner />
  </div>
  <template v-else>
    <Card
      class="my-3"
    >
      <template #title>
        <h2 class="text-2xl">
          Change password
        </h2>
      </template>
      <template #content>
        <div class="mb-3">
          <label
            for="password1"
            class="block text-900 font-medium mb-2"
          >Password</label>
          <Password
            id="password1"
            v-model="values.password"
            placeholder="Password"
            :toggle-mask="true"
            :class="['w-full', 'mb-1', !!errors.password && 'p-invalid']"
            input-class="w-full"
            input-style="padding:1rem"
            @blur="validate('password')"
            @keypress="validate('password')"
          />
          <span
            v-if="!!errors.password"
            class="block error-text"
          >
            {{ errors.password }}
          </span>
        </div>
        <div class="mb-3">
          <label
            for="password2"
            class="block text-900 font-medium mb-2"
          >Repeat Password</label>
          <Password
            id="password2"
            v-model="values.password2"
            placeholder="Repeat Password"
            :toggle-mask="true"
            :class="['w-full', 'mb-1', !!errors.password && 'p-invalid']"
            input-class="w-full "
            input-style="padding:1rem"
            :feedback="false"
            @blur="validate('password2')"
            @keypress="validate('password2')"
          />
          <span
            v-if="!!errors.password2"
            class="error-text"
          >
            {{ errors.password2 }}
          </span>
        </div>
        <Button
          label="Change password"
          class="p-button-primary"
          :disabled="loading"
          @click="changePasswordHandler"
        />
      </template>
    </Card>
    <Card class="mb-3">
      <template #title>
        <h2 class="text-2xl">
          Your plan
        </h2>
      </template>
      <template #content>
        <YourPlan
          :initial-subscription="false"
          :initial-auto-upgrade="getInitialAutoUpgrade()"
        />
      </template>
    </Card>
    <Card class="mb-3">
      <template #title>
        <h2 class="text-2xl">
          Billing Details
        </h2>
      </template>
      <template #content>
        <p>Access your payment details and billing history here.</p>
        <a
          :href="getPaymentDetailsUrl(email)"
          target="_blank"
        ><Button
          icon="pi pi-external-link"
          label="Payments Portal"
        /></a>
      </template>
    </Card>
  </template>
  <Toast />
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapGetters, mapMutations } from 'vuex';
import { object, string, ref } from 'yup';
// eslint-disable-next-line no-unused-vars
import A2CAClient from '../../../api/a2caAuthorizedClient';
import YourPlan from '../plan/YourPlan.vue';
import { SHOW_PAYMENT_SUCCESS_BANNER } from '../../../constants/storageConstants';

const signupSchema = object().shape({
  password: string().min(4, 'Password must contain 4 characters at least').required(),
  password2: string().oneOf([ref('password'), null], 'Passwords do not match'),
});

export default {
  components: {
    YourPlan,
  },
  inject: ['query'],
  props: {
  },
  data() {
    return {
      initialAutoUpgrade: null,
      values: {
        password: '',
        password2: '',
      },
      errors: {
        password: '',
        password2: '',
      },
      loading: false,
      loadingAutoUpgrade: false,
    };
  },
  computed: {
    ...mapGetters(['email', 'settings']),
  },
  async created() {
    this.loadingAutoUpgrade = true;
    const client = new A2CAClient(this.query);

    const initialAutoUpgrade = await client.getAutoUpgrade();
    this.initialAutoUpgrade = initialAutoUpgrade;

    if (JSON.parse(localStorage.getItem(SHOW_PAYMENT_SUCCESS_BANNER) ?? 'false')) {
      this.$toast.add({
        severity: 'success',
        summary: 'Plan updated',
        detail: 'The plan update has been successful!',
        life: 1500,
      });
      localStorage.setItem(SHOW_PAYMENT_SUCCESS_BANNER, JSON.stringify(false));
    }

    this.loadingAutoUpgrade = false;
  },
  methods: {
    getPaymentDetailsUrl(email) {
      return encodeURI(new URL(`?prefilled_email=${email}`, process.env.VUE_APP_STRIPE_PAYMENT_DETAILS_URL).href);
    },
    changePasswordHandler() {
      signupSchema
        .validate(this.values, { abortEarly: false })
        .then(async () => {
          this.errors = {};
          this.loading = true;
          try {
            const client = new A2CAClient(this.query);
            await client.changePassword(this.values.password);
            this.$toast.add({
              severity: 'success',
              summary: 'Password changed',
              detail: 'Your password was changed successfully',
              life: 3000,
            });
          } catch (err) {
            this.$toast.add({
              severity: 'error',
              summary: 'Password could not be changed',
              detail: err,
              life: 3000,
            });
          }
          this.loading = false;
        })
        .catch((err) => {
          err.inner.forEach((error) => {
            this.errors[error.path] = error.message;
          });
          this.$toast.add({
            severity: 'error',
            summary: 'Password could not be changed',
            detail: 'Passwords must match and contain 4 characters at least',
            life: 3000,
          });
        });
    },
    validate(field) {
      signupSchema
        .validateAt(field, this.values, { abortEarly: true })
        .then(() => {
          this.errors[field] = '';
        })
        .catch((err) => {
          this.errors[field] = err.message;
        });
    },
    getInitialAutoUpgrade() {
      return this.initialAutoUpgrade;
    },
  },
};
</script>

<style scoped lang="scss">
.error-text {
  color: red
}
</style>
