<template>
  <div v-if="loading">
    <ProgressSpinner />
  </div>

  <div
    v-else
  >
    <div v-if="plan === null || (pricing.status === 'CANCELLED'&& pricing.subscriptionActive === false)">
      <p>You have no plan.</p>
      <router-link
        :to="{name:'subscribe', query}"
      >
        <Button
          type="button"
          label="See plans"
          class="p-button-sm p-button-secondary"
        />
      </router-link>
    </div>
    <div
      v-else
      class="flex justify-content-center"
    >
      <div
        v-for="currentPlan in [plan]"
        :key="currentPlan.id"
        class="your-plan"
      >
        <div class="p-3 h-full">
          <div
            class="shadow-2 p-3 h-full flex flex-column surface-card"
            style="border-radius: 6px"
          >
            <div class="grid">
              <div class="text-900 font-bold text-xl col-2">
                {{ getPlanName(currentPlan.planType).name }}
              </div>
              <div class="flex justify-content-end gap-3 col-10">
                <span
                  class=" text-900 "
                >{{ getCurrencySymbol() }}{{ getCurrentSubscriptionBasePrice() }} {{ currentPlan.variableRate !== "NONE"? formatVariableRate(currentPlan.variableRate) : "" }}<span v-if="getCurrentSubscriptionDiscount() !== null"> (-{{ getCurrentSubscriptionDiscount() }}%)</span>
                </span>
              </div>
            </div>
            <div class="grid">
              <div class="text-900 font-bold col-6">
                Current billing cycle
              </div>
              <div class="flex justify-content-end gap-3 col-6">
                <span
                  class=" text-900 "
                >{{ formatPlanDate(pricing.startDate) }} - {{ formatPlanDate(pricing.disableDate) }}</span>
              </div>
            </div>
            <div class="grid">
              <div class="text-900 font-bold col-6">
                Ad spend this billing cycle  <span
                  v-tooltip="'BooksFlyer displays only active campaigns. The ad spend reflects costs from all campaigns enabled within this billing cycle regardless of their current status.'"
                  class="pi pi-info-circle"
                />
              </div>
              <div class="flex justify-content-end gap-3 col-6">
                <span
                  class=" text-900 "
                >{{ getCurrencySymbol() }}{{ getCurrentAdSpend() }}</span>
              </div>
            </div>
            <div class="grid">
              <div class="text-900 font-bold col-6">
                Active profiles
              </div>
              <div class="flex justify-content-end gap-3 col-6">
                <span
                  class=" text-900 "
                >{{ Math.min(numberOfEnabledProfiles, currentPlan.maxNumberOfProfiles ?? Number.MAX_SAFE_INTEGER) }} of {{ currentPlan.maxNumberOfProfiles ?? "Unlimited" }}</span>
              </div>
            </div>
            <div
              v-if="!(pricing.status === 'CANCELLED'&& pricing.subscriptionActive === true)"
              class="grid"
            >
              <div class="text-900 font-bold col-6">
                Next billing date
              </div>
              <div class="flex justify-content-end gap-3 col-6">
                <span
                  class=" text-900 "
                >{{ formatPlanDate(pricing.disableDate) }}</span>
              </div>
            </div>
            <div>
              <span
                v-if="pricing.status === 'CANCELLED'&& pricing.subscriptionActive === true"
                class="text-sm"
              >Your plan is currently active only until {{ formatPlanDate(pricing.disableDate) }}. If you want to continue using the tool, feel free to enroll in a plan! </span>
            </div>
            <hr class="w-full">
            <div class="grid">
              <div class="text-900 font-bold col-6">
                Ad spend auto upgrade <span
                  v-tooltip="'Get an automatic upgrade to the next plan if you reach your ad spend limit, so that your bids are optimized even while the ad spend increases.'"
                  class="pi pi-info-circle"
                />
              </div>
              <div class="flex justify-content-end align-items-center gap-3 col-6">
                <InputSwitch
                  id="selectedAutoUpgrade"
                  v-model="autoUpgrade"
                />
              </div>
            </div>
            <div class="height-none">
              <span class="text-sm visibility-hidden">Get an automatic upgrade to the next plan if you reach your ad spend limit, so that your bids are optimized even while the ad spend increases.</span>
            </div>

            <div>
              <Accordion>
                <AccordionTab header="See more">
                  <p>
                    -In your case, the next plan is the {{ getPlanName(currentPlan.planType).name }} with {{ currentPlan.planType === "ENTERPRISE" ? "Over" : "Up to" }} ${{ currentPlan.maxAdSpend ?? "5000" }}/month. If you reach the limit, you'll pay the difference between the new plan and the old one.
                  </p>
                  <p>
                    -You'll stay on the higher tier after the auto-upgrade, unless you manually select a lower plan.
                  </p>
                  <p>
                    -The auto upgrade will keep on happening while the toggle is activated. If you wish to stop it, please deactivate the toggle.
                  </p>
                </AccordionTab>
              </Accordion>
            </div>

            <div
              v-if="downgradePlan !== null"
              class="mt-1"
            >
              <span

                class="text-sm"
              >This plan will change to {{ getPlanName(downgradePlan).name }} on the next renewal date: {{ formatPlanDate(pricing.disableDate) }}.</span>
            </div>

            <div class="flex flex-row gap-3 align-items-center">
              <Button
                class="p-button-primary my-3 text-l "
                label="Change plan"
                :disabled="loadingPayment"
                @click=" $router.push({ name: 'subscribe', query })"
              />
              <Button
                v-if="pricing.status === 'CANCELLED'&& pricing.subscriptionActive === true"
                class="p-button-secondary my-3 text-l "
                label="Reactivate plan"
                :disabled="loadingPayment"
                @click="buyPlan()"
              />
              <Button
                v-else
                class="p-button-secondary my-3 text-l "
                label="Cancel plan"
                :disabled="loadingPayment"
                @click="showCancelPopup($event)"
              />
            </div>
            <ConfirmPopup>
              <template #message="slotProps">
                <div class="flex p-4">
                  <i
                    :class="slotProps.message.icon"
                    style="font-size: 1.5rem"
                  />
                  <p class="pl-2">
                    {{ slotProps.message.message }}
                  </p>
                </div>
              </template>
            </ConfirmPopup>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import dayjs from 'dayjs';
import A2CAAuthorizedClient from '../../../api/a2caAuthorizedClient';
import { findPlanByType } from '../../../constants/planConstants';
import formatDecimal, { currencySymbolFromCountry } from '../../../utils/formatUtils';
import { SHOW_PAYMENT_SUCCESS_BANNER } from '../../../constants/storageConstants';

export default {
  inject: ['query'],
  props: {
    initialSubscription: {
      type: Boolean,
      required: false,
      default: false,
    },
    initialAutoUpgrade: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      loadingPayment: false,
      currentPlans: null,
      currentSubscription: null,
      downgradePlan: null,
      autoUpgrade: this.initialAutoUpgrade,
      updatingAutoUpgrade: false,
    };
  },

  computed: {
    ...mapGetters([
      'plan', 'pricing', 'currentAdSpend', 'numberOfEnabledProfiles',
    ]),
  },
  watch: {
    autoUpgrade: {
      handler() {
        this.handleUpdateAutoUpgrade();
      },
    },
  },
  async created() {
    this.loading = true;
    const client = new A2CAAuthorizedClient(this.query);

    const planInformation = await client.getPlanInformation();
    const currentSubscription = await client.getCurrentSubscription();

    this.currentSubscription = currentSubscription;
    const { currentPricing, downgradePlan } = planInformation;
    this.setPricing(currentPricing);
    this.currentPlans = planInformation.plans;
    this.downgradePlan = downgradePlan;
    this.loading = false;
  },
  methods: {
    formatPlanDate(date) {
      return dayjs(date).format('YYYY-MM-DD');
    },
    getCurrencySymbol() {
      return currencySymbolFromCountry(this.$store.state.auth.account.country);
    },
    getPlanName(plan) {
      return findPlanByType(plan);
    },
    getCurrentSubscriptionBasePrice() {
      if (this.currentSubscription.discount === null || this.currentSubscription.discount === undefined || this.downgradePlan !== null) {
        return this.plan.basePrice;
      }
      const { percentOff } = this.currentSubscription.discount.coupon;
      return formatDecimal(this.plan.basePrice * (1 - percentOff / 100));
    },
    getCurrentSubscriptionDiscount() {
      if (this.currentSubscription.discount === null || this.currentSubscription.discount === undefined || this.downgradePlan !== null) {
        return null;
      }
      const { percentOff } = this.currentSubscription.discount.coupon;
      return percentOff;
    },
    showCancelPopup(event) {
      this.$confirm.require({
        target: event.currentTarget,
        acceptClass: 'p-button-danger',
        acceptLabel: 'Cancel plan',
        message: 'Are you sure you want to cancel your current plan subscription?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.cancelPlan();
        },
      });
    },
    async buyPlan() {
      const { plan } = this;
      const client = new A2CAAuthorizedClient(this.query);
      this.loadingPayment = true;
      try {
        const checkout = await client.buyPlan(plan.planType, this.selectedAutoUpgrade, this.discountCodeToApply, this.selectedProfiles);
        if (checkout.url !== null) {
          localStorage.setItem(SHOW_PAYMENT_SUCCESS_BANNER, JSON.stringify(true));
          window.location.href = checkout.url;
        } else {
          localStorage.setItem(SHOW_PAYMENT_SUCCESS_BANNER, JSON.stringify(true));
          this.$router.push({ name: 'myAccountInfo' });
        }
      } catch (err) {
        this.$toast.add({
          severity: 'error',
          summary: 'Payment not successful',
          detail: 'The payment has not been successful. Please try again or contact us.',
          life: 1500,
        });
        console.error(err);
      }
      this.loadingPayment = false;
    },
    async cancelPlan() {
      const client = new A2CAAuthorizedClient(this.query);
      this.loadingPayment = true;
      try {
        const resultPricing = await client.cancelPlan();
        this.setPricing(resultPricing);
        this.$toast.add({
          severity: 'success',
          summary: 'Subscription cancelled.',
          detail: `You have successfully cancelled your subscription to Booksflyer. You will be able to use the tool until ${this.formatPlanDate(resultPricing.disableDate)}`,
          life: 10000,
        });
      } catch (err) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error cancelling subscription',
          detail: 'Your subscription could not be cancelled. Please try again.',
          life: 1500,
        });
        console.error(err);
      }
      this.loadingPayment = false;
      // this.logout();
      if (this.initialSubscription) {
        this.$router.push({ name: 'login' });
      }
    },
    async handleUpdateAutoUpgrade() {
      const client = new A2CAAuthorizedClient(this.query);
      this.updatingAutoUpgrade = true;
      try {
        const resultAutoUpgrade = await client.updateAutoUpgrade(this.autoUpgrade);
        this.autoUpgrade = resultAutoUpgrade;
        this.$toast.add({
          severity: 'success',
          summary: 'Autoupgrade changed.',
          detail: 'Your autoupgrade settings have changed correctly',
          life: 10000,
        });
      } catch (err) {
        this.$toast.add({
          severity: 'error',
          summary: 'Autoupgrade failed',
          detail: 'Could not update your autoupgrade settings.',
          life: 1500,
        });
        console.error(err);
      }
      this.updatingAutoUpgrade = false;
    },
    formatVariableRate(variableRate) {
      const variableRateRegex = /AD_SPEND_(\d)_PERCENT_OVER_(\d+)/;
      const match = variableRate.match(variableRateRegex);
      let variableRatePercentage;

      const currentDiscountPercentage = this.getCurrentSubscriptionDiscount();
      if (currentDiscountPercentage === null) {
        // eslint-disable-next-line prefer-destructuring
        variableRatePercentage = match[1];
      } else {
        variableRatePercentage = match[1] * (1 - (currentDiscountPercentage / 100));
      }
      return `+${formatDecimal(variableRatePercentage)}% of ad spend over $${match[2]}`;
    },
    getCurrentAdSpend() {
      return formatDecimal(this.currentAdSpend);
    },
    ...mapMutations(['setPricing', 'logout']),
  },

};
</script>

<style scoped>
  .target-dropdown {
      flex: 2;
    }
  .plan-cost {
    font-size: 2.4rem;
  }
  .gap-3 {
    gap: 1rem;
  }
  .visibility-hidden {
    visibility: hidden;
  }

  .plan-marketing-labels {
    margin-top: 18rem;
    align-items: last baseline;
  }

  .inline-grid {
    display: inline-grid !important;
  }

  .full-dropdown {
      min-width: 25rem;
      max-width: 25rem;
    }

    .your-plan {
      max-width: 45%;
    }

    .height-none {
      height: 0;
    }
</style>

<style>
    .p-accordion-header-link {
      border: none !important;
      background-color: white !important;
      padding: 0 !important;
    }
</style>
